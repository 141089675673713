<template>
  <el-dialog title="事项详情" :visible.sync="outerVisible">
    <matter-info :matterData="matterData"></matter-info>
    <div slot='footer'>
      <slot name='matter'></slot>
    </div>
  </el-dialog>
</template>

<script>
import MatterInfo from "@/components/matter";
import { crmGetItemInfo, crmGetCustomList } from "@/api/crm/resource_library";
import { getAttachmentList, getRecord } from "@/api/crm/workbench";
export default {
  data() {
    return {
      activeIndex: 0,
      outerVisible: false,
      matterData: {},
      edit: false,
    };
  },
  components: {
    MatterInfo,
  },
  methods: {
    open(id) {
      this.matterData = {};
      this.outerVisible = true;
      this.getData(id);
    },
    close() {
      this.outerVisible = false;
    },
    getData(id) {
      crmGetItemInfo(id).then((res) => {
        if (res.status == 200) {
          this.$set(this.matterData, "matterInfo", res.data);
          this.$set(this.matterData, "lstEnterprise", [
            { entName: res.data.resName },
          ]);
        }
      });
      this.getCustomList(id);
      this.getRecord(id);
    },
    getCustomList(id) {
      crmGetCustomList("1", id).then((res) => {
        if (res.status == 200) {
          this.$set(this.matterData, "lstCutomer", res.data);
        }
      });
    },
    getRecord(id) {
      let lstRecord = [];
      getRecord(id).then((res) => {
        if (res.data.length > 0) {
          res.data.forEach(async (val) => {
            lstRecord.push({
              id: val.id,
              time: val.time,
              reason:val.reason,
              remark:val.remark,
              lstAttachment: await this.getAttachmentList(val.id),
            });
          });
        } else {
          lstRecord = [{time:'',lstAttachment:[]}];
        }
        this.$set(this.matterData, "lstRecord", lstRecord);
      });
    },
    getAttachmentList(id) {
      return getAttachmentList("1/" + id).then((res) => {
        if (res.status == 200) {
          return res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-dialog {
  width: 70%;
}
.btns {
  display: flex;
  justify-content: flex-end;
  .red {
    color: #fc555e;
    border: 1px solid #fc555e;
  }
}
</style>