<template>
  <div
    class="content-create-detail padding-content"
    v-if="
      matterData.matterInfo && Object.keys(matterData.matterInfo).length > 0
    "
  >
    <div class="title-box border-bottom">
      <div class="title-name">
        事项名称：{{ matterData.matterInfo.itemName }}
      </div>
      <div class="title-time">{{ matterData.matterInfo.time }}</div>
      <div class="title-state">
        <span>{{ matterData.matterInfo.itemType | itemTypeFilter }}</span>
      </div>
    </div>
    <ul>
      <li class="border-bottom matter-name">
        <span class="label">事项名称：</span>
        <span class="value">{{ matterData.matterInfo.itemName }}</span>
      </li>
      <li class="border-bottom content-create-detail-box">
        <div class="border">
          <div class="title">企业信息</div>
          <div class="content item">
            <div class="flex-1">
              <span class="label">企业名称：</span>
              <span class="value">{{ matterData.matterInfo.resName }}</span>
            </div>
            <div>
              <span class="label">地点：</span>
              <span class="value">{{ matterData.matterInfo.address }}</span>
            </div>
            <div>
              <span class="label">所属产业：</span>
              <span class="value"
                >{{ matterData.matterInfo.industry }}&nbsp;{{
                  matterData.matterInfo.industryDesc
                }}</span
              >
            </div>
            <div class="flex">
              <div class="flex-1">
                <span class="label">我方陪同人员：</span>
                <span class="value">{{
                  matterData.matterInfo.receptionist
                }}</span>
              </div>
              <div>
                <span class="label">备注：</span>
                <span class="value">{{
                  matterData.matterInfo.remark | filterRemark
                }}</span>
              </div>
            </div>
            <div>
              <span class="label">线索渠道来源：</span>
              <span class="value"
                >{{ matterData.matterInfo.channelSource }}&nbsp;{{
                  matterData.matterInfo.channelSourceDesc
                }}</span
              >
            </div>
            <div>
              <span class="label">对此次事项评价：</span>
              <span class="value">{{ matterData.matterInfo.evaluation }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div>
      <ul
        class="border-bottom content-create-detail-box"
        v-if="matterData.lstCutomer && matterData.lstCutomer.length > 0"
      >
        <li
          class="item"
          v-for="(item, index) in matterData.lstCutomer"
          :key="item.id"
        >
          <div class="border">
            <div class="title">企业联系人{{ index + 1 }}</div>
            <div class="content">
              <div class="flex flex-between">
                <div class="marginBottom">
                  <span class="label"> 联系人姓名：</span>
                  <span class="value">{{ item.cstName }}</span>
                </div>
                <div>
                  <span class="label">联系人职务：</span>
                  <span class="value">{{ item.position }}</span>
                </div>
              </div>
              <div class="flex-1">
                <span class="label">联系方式：</span>
                <span class="value">{{ item.tel }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="content-create-detail-box"
      v-if="matterData.lstRecord && matterData.lstRecord.length > 0"
    >
      <div
        class="marb"
        v-for="(item, index) in matterData.lstRecord"
        :key="item.id"
      >
        <div v-if="item.id" class="border item">
          <div class="title">会议资料{{ index + 1 }}</div>
          <ul class="file-group content">
            <li>
              <div class="label">时间：</div>
              <div class="value active-color">{{ item.time }}</div>
            </li>
            <li>
              <div class="label">外出/接待事由：</div>
              <div class="value value-txt">{{ item.reason }}</div>
            </li>
            <li v-for="lab in labels" :key="lab.id">
              <div class="label">{{ lab.name }}</div>
              <a
                class="value active-color"
                :href="staticURL + i.fileName"
                v-for="i in dealData(index, lab.name)"
                :key="i.fileName"
                >{{ i.originalName }}</a
              >
            </li>
            <li>
              <div class="label">备注：</div>
              <div class="value value-txt">{{ item.remark }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
export default {
  props: {
    matterData: {
      type: Object,
    },
  },
  filters: {
    itemTypeFilter(status) {
      switch (status) {
        case 1:
          return "外出";
        case 2:
          return "接待";
      }
    },
    filterRemark(status) {
      switch (status) {
        case 1:
          return "走出去";
        case 2:
          return "请进来";
      }
    },
  },
  computed: {},
  data() {
    return {
      staticURL: URL.static,
      labels: [
        // {
        //   name: "事件/访问函",
        //   id: 1,
        // },
        {
          name: "洽谈结果",
          id: 2,
        },
        {
          name: "外出/接待方案",
          id: 3,
        },
        {
          name: "现场照片",
          id: 4,
        },
        {
          name: "企业资料",
          id: 5,
        },
      ],
    };
  },
  methods: {
    dealData(index, name) {
      return this.matterData.lstRecord[index].lstAttachment.filter(
        (val) => val.docTypeName == name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.marb {
  margin-bottom: 26px;
}
.matter-name {
  padding: 30px 0;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.title-box {
  font-size: 20px;
  color: #374567;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  .title-time {
    margin-left: 20px;
    margin-right: 9px;
  }
  .title-state {
    padding: 2px 16px;
    border: 1px solid rgba(55, 69, 103, 0.65);
    color: rgba(55, 69, 103, 0.65);
    font-size: 14px;
  }
}
.label {
  font-size: 14px;
  margin-right: 10px;
  color: rgba(55, 69, 103, 0.75);
  display: inline-block;
  width: 120px;
  text-align: right;
  font-weight: 400;
}
.value {
  font-size: 14px;
  color: #374567;
  font-weight: 500;
}
.border-bottom {
  border-bottom: 1px solid #e1e6f0;
}
.marginBottom {
  margin-bottom: 26px;
}
.content-create-detail .item {
  > div {
    margin-bottom: 26px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
}
.file-group {
  .label {
    text-align: left;
  }
  > li {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 17px;
  }
  .value {
    margin: 17px 10px 0;
    color: #00a0e9;
    font-size: 14px;
    display: block;
  }
  .value-txt{
    color: #374567;
  }
}
</style>